<template>
  <NavigationTab />
  <NuxtLoadingIndicator color="#1B5FA9" />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <Breaky />
</template>
