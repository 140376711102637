<template>
  <ContentSection>
    <Container indent="container-wide">
      <div class="grid grid-cols-1 gap-10 sm:gap-14 lg:grid-cols-3">
        <!-- MAP -->
        <div class="relative col-span-1 aspect-[4/3] md:col-span-2">
          <GoogleMap
            :api-key="$config.GMAPS_KEY"
            style="width: 100%; height: 100%"
            :center="{ lat: 47.3748152404601, lng: 8.04445145346212 }"
            :zoom="15"
          >
            <Marker
              :options="{
                position: { lat: 47.3748152404601, lng: 8.04445145346212 },
              }"
            />
          </GoogleMap>
        </div>

        <!-- TEXT -->
        <div
          v-if="store.globals"
          class="col-span-1 space-y-5 text-sm sm:space-y-10 sm:text-base"
        >
          <div class="lg:mt-4">
            <h2 class="mb-4 max-w-title text-base font-bold sm:mb-6 sm:text-lg">
              {{ store.globals.address_title }}
            </h2>
            <!-- ADDRESS -->
            <address
              v-if="store.globals.address"
              class="whitespace-pre not-italic"
              v-html="store.globals.address"
            />
          </div>

          <div v-if="store.globals.phone || store.globals.email">
            <!-- PHONE -->
            <a
              v-if="store.globals.phone"
              class="block"
              :href="`tel:${store.globals.phone}`"
              >{{ store.globals.phone }}</a
            >
            <!-- MAIL -->
            <a
              v-if="store.globals.email"
              class="block"
              :href="`mailto:${store.globals.email}`"
              >{{ store.globals.email }}</a
            >
          </div>

          <!-- PC ACCOUNT -->
          <div v-if="store.globals.pc_account">
            <h3>PC-Konto:</h3>
            <p class="max-w-text">{{ store.globals.pc_account }}</p>
          </div>

          <!-- PUBLIC TRANSPORT -->
          <div
            v-if="
              store.globals.public_transport_title ||
              store.globals.public_transport_text
            "
          >
            <h2
              class="mb-4 max-w-title pt-3 text-base font-bold sm:mb-6 sm:text-lg"
            >
              {{ store.globals.public_transport_title }}
            </h2>
            <p
              class="max-w-text"
              v-html="store.globals.public_transport_text"
            />
            <AppLinkWithIcon
              v-if="store.globals.public_transport_link"
              class="mt-4 sm:mt-5"
              :to="store.globals.public_transport_link"
              >{{ store.globals.public_transport_link_title }}</AppLinkWithIcon
            >
          </div>

          <!-- PRIVATE TRANSPORT -->
          <div
            v-if="
              store.globals.private_transport_title ||
              store.globals.private_transport_text
            "
          >
            <h2
              class="mb-4 max-w-title pt-3 text-base font-bold sm:mb-6 sm:text-lg"
            >
              {{ store.globals.private_transport_title }}
            </h2>
            <p
              class="max-w-text"
              v-html="store.globals.private_transport_text"
            />
            <AppLinkWithIcon
              v-if="store.globals.private_transport_link"
              class="mt-4 text-sm sm:mt-5 sm:text-base"
              :to="store.globals.private_transport_link"
              >{{ store.globals.private_transport_link_title }}</AppLinkWithIcon
            >
          </div>
        </div>
      </div>
    </Container>
  </ContentSection>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'
import { store } from '/store.js'

export default {
  components: {
    GoogleMap,
    Marker,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      store,
    }
  },

  async mounted() {
    // await for 200 ms
    await new Promise((resolve) => setTimeout(resolve, 1000))
  },
}
</script>
