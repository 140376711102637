<template>
  <div>
    <!-- IMAGE -->
    <div class="pl-[55%]">
      <AppImage
        v-if="person.portrait"
        loading="lazy"
        :src="person.portrait"
        class="rounded-full"
        :aspect-ratio="1"
      />
    </div>
    <!-- TEXT -->
    <div
      class="-mt-10 rounded-md bg-white p-6 pt-[3.75rem] sm:p-9 sm:pt-[4.75rem]"
    >
      <h3 class="mb-5 text-[1.875rem] font-medium sm:mb-8 sm:text-2xl">
        {{ person.title }}
      </h3>

      <!-- FUNCTION -->
      <p class="text-sm font-medium sm:text-base">
        {{
          Array.isArray(person.categories) && person.categories.length > 0
            ? person.categories.map((f) => f.title).join(', ')
            : person.categories[0].title
        }}
      </p>

      <div class="mt-1 flex flex-col space-y-1">
        <!-- EMAIL -->
        <AppLink
          v-if="person.email"
          class="text-sm font-medium sm:text-base"
          :to="`mailto:${person.email}`"
          >{{ person.email }}</AppLink
        >
        <!-- PHONE -->
        <AppLink
          v-if="person.phone"
          class="text-sm font-medium sm:text-base"
          :to="`tel:${person.phone}`"
          >{{ person.phone }}</AppLink
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
}
</script>
