<template>
  <ContentSection>
    <Container v-if="data.images.length === 1" indent="container-tight">
      <!-- SINGLE IMAGE -->
      <figure>
        <div>
          <AppImage
            v-if="data.images[0].image"
            :src="data.images[0].image"
            class="h-full w-full rounded-lg object-contain"
            :aspect-ratio="3 / 2"
            loading="lazy"
          />
        </div>

        <figcaption
          v-if="data.images[0].text"
          class="mt-4 max-w-text text-[0.875rem] sm:mt-5"
        >
          {{ data.images[0].text }}
        </figcaption>
      </figure>
    </Container>
    <Container v-if="data.images.length > 1" indent="container-tight">
      <!-- MULTIPLE IMAGES -->
      <div
        v-if="data.images.length > 1"
        class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 xl:gap-14"
      >
        <figure
          v-for="(image, index) in data.images"
          :key="index"
          class="aspect-[3/2]"
        >
          <div>
            <AppImage
              v-if="image.image"
              :src="image.image"
              class="h-full w-full rounded-lg object-contain"
              :aspect-ratio="3 / 2"
              loading="lazy"
            />
          </div>
          <figcaption
            v-if="image.text"
            class="mt-4 max-w-text text-[0.875rem] sm:mt-5"
          >
            {{ image.text }}
          </figcaption>
        </figure>
      </div>
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
