<template>
  <ContentSection>
    <Container indent="container-tight">
      <VideoPlayer
        :key="data.video_file || data.video_url"
        :file="data.video_file"
        :video-url="data.video_url"
        :overlay-image="data.overlay_image"
        :caption="data.caption"
      />
    </Container>
  </ContentSection>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
