<template>
  <Container indent="container-default sm:container-tighter">
    <div
      class="text-base leading-8 tracking-[-0.01em] md:text-[1.75rem] md:!leading-10"
      v-html="lead"
    />
  </Container>
</template>

<script>
export default {
  props: {
    lead: {
      type: String,
      default: '',
    },
  },
}
</script>
