import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U4BUIcs89R from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import gsap_qE0fizvEy0 from "/vercel/path0/plugins/gsap.ts";
import livePreview_9NO16x2OtG from "/vercel/path0/plugins/livePreview.ts";
import removePTags_NNOtr2gdeF from "/vercel/path0/plugins/removePTags.ts";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
import vue_cloudinary_image_statamic_CYF7m5qqCy from "/vercel/path0/plugins/vue-cloudinary-image-statamic.js";
import vue_cloudinary_image_AN6cd3OYHt from "/vercel/path0/plugins/vue-cloudinary-image.js";
import vue_gtag_client_fbnXi0Aqdd from "/vercel/path0/plugins/vue-gtag.client.js";
import vue3_lottie_client_xXNcInN2H5 from "/vercel/path0/plugins/vue3-lottie.client.ts";
import vue3_youtube_85VsOCUAX8 from "/vercel/path0/plugins/vue3-youtube.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_U4BUIcs89R,
  gsap_qE0fizvEy0,
  livePreview_9NO16x2OtG,
  removePTags_NNOtr2gdeF,
  sentry_client_GoGQuZo4Et,
  veeValidate_U1l0qzVbBC,
  vue_cloudinary_image_statamic_CYF7m5qqCy,
  vue_cloudinary_image_AN6cd3OYHt,
  vue_gtag_client_fbnXi0Aqdd,
  vue3_lottie_client_xXNcInN2H5,
  vue3_youtube_85VsOCUAX8
]