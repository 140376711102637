import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyAnimatedContainer, LazyAppButton, LazyAppLink, LazyAppLinkWithIcon, LazyArrowButton, LazyBlogOverview, LazyBreadcrumbs, LazyBreaky, LazyComponentsLoader, LazyContainer, LazyContentSection, LazyCookieBanner, LazyEntryCard, LazyEventCard, LazyEventCardAccordion, LazyEventOverview, LazyFilter, LazyFloater, LazyHerosLoader, LazyLeadText, LazyScrollDownIndicator, LazySliderButtons, LazyTeamCard, LazyTeamOverview, LazyVideoPlayer, LazyBlueprintBlogOverview, LazyBlueprintBlogPost, LazyBlueprintContentPage, LazyBlueprintEvent, LazyBlueprintEventOverview, LazyBlueprintJob, LazyBlueprintPage, LazyBlueprintSection, LazyBlueprintTeamOverview, LazyContentAccordionGroup, LazyContentBlogSlider, LazyContentContactTeaser, LazyContentDownloads, LazyContentEventCards, LazyContentGallery, LazyContentGoogleMaps, LazyContentIconCards, LazyContentImages, LazyContentImagesAsShapes, LazyContentJobCards, LazyContentPartnerLogos, LazyContentPrivacybee, LazyContentRaiseNowWidget, LazyContentSlider, LazyContentTeaserLargeSlider, LazyContentText, LazyContentVideoDefault, LazyHeroBlog, LazyHeroDefault, LazyHeroEvent, LazyHeroJob, LazyHeroHome, LazyHeroSmall, LazyIconArrow, LazyIconArrowDash, LazyIconArrowSimple, LazyIconBlueString, LazyIconBurger, LazyIconCheckmark, LazyIconCross, LazyIconDownload, LazyIconEar, LazyIconEye, LazyIconEyeAndEar, LazyIconEyeAndEarFull, LazyIconFlag, LazyIconHeart, LazyIconLogo, LazyIconPlay, LazyIconPlus, LazyIllustrationEyesAndEars, LazyIllustrationSquiggle, LazyIllustrationSquiggleDot, LazyNavigationDesktop, LazyNavigationDesktopDropdown, LazyNavigationFooter, LazyNavigationHoverUnderline, LazyNavigationMain, LazyNavigationMobile, LazyNavigationMobilePopup, LazyNavigationSimpleLang, LazyNavigationTab, LazyUtilsTime } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["AnimatedContainer", LazyAnimatedContainer],
["AppButton", LazyAppButton],
["AppLink", LazyAppLink],
["AppLinkWithIcon", LazyAppLinkWithIcon],
["ArrowButton", LazyArrowButton],
["BlogOverview", LazyBlogOverview],
["Breadcrumbs", LazyBreadcrumbs],
["Breaky", LazyBreaky],
["ComponentsLoader", LazyComponentsLoader],
["Container", LazyContainer],
["ContentSection", LazyContentSection],
["CookieBanner", LazyCookieBanner],
["EntryCard", LazyEntryCard],
["EventCard", LazyEventCard],
["EventCardAccordion", LazyEventCardAccordion],
["EventOverview", LazyEventOverview],
["Filter", LazyFilter],
["Floater", LazyFloater],
["HerosLoader", LazyHerosLoader],
["LeadText", LazyLeadText],
["ScrollDownIndicator", LazyScrollDownIndicator],
["SliderButtons", LazySliderButtons],
["TeamCard", LazyTeamCard],
["TeamOverview", LazyTeamOverview],
["VideoPlayer", LazyVideoPlayer],
["BlueprintBlogOverview", LazyBlueprintBlogOverview],
["BlueprintBlogPost", LazyBlueprintBlogPost],
["BlueprintContentPage", LazyBlueprintContentPage],
["BlueprintEvent", LazyBlueprintEvent],
["BlueprintEventOverview", LazyBlueprintEventOverview],
["BlueprintJob", LazyBlueprintJob],
["BlueprintPage", LazyBlueprintPage],
["BlueprintSection", LazyBlueprintSection],
["BlueprintTeamOverview", LazyBlueprintTeamOverview],
["ContentAccordionGroup", LazyContentAccordionGroup],
["ContentBlogSlider", LazyContentBlogSlider],
["ContentContactTeaser", LazyContentContactTeaser],
["ContentDownloads", LazyContentDownloads],
["ContentEventCards", LazyContentEventCards],
["ContentGallery", LazyContentGallery],
["ContentGoogleMaps", LazyContentGoogleMaps],
["ContentIconCards", LazyContentIconCards],
["ContentImages", LazyContentImages],
["ContentImagesAsShapes", LazyContentImagesAsShapes],
["ContentJobCards", LazyContentJobCards],
["ContentPartnerLogos", LazyContentPartnerLogos],
["ContentPrivacybee", LazyContentPrivacybee],
["ContentRaiseNowWidget", LazyContentRaiseNowWidget],
["ContentSlider", LazyContentSlider],
["ContentTeaserLargeSlider", LazyContentTeaserLargeSlider],
["ContentText", LazyContentText],
["ContentVideoDefault", LazyContentVideoDefault],
["HeroBlog", LazyHeroBlog],
["HeroDefault", LazyHeroDefault],
["HeroEvent", LazyHeroEvent],
["HeroJob", LazyHeroJob],
["HeroHome", LazyHeroHome],
["HeroSmall", LazyHeroSmall],
["IconArrow", LazyIconArrow],
["IconArrowDash", LazyIconArrowDash],
["IconArrowSimple", LazyIconArrowSimple],
["IconBlueString", LazyIconBlueString],
["IconBurger", LazyIconBurger],
["IconCheckmark", LazyIconCheckmark],
["IconCross", LazyIconCross],
["IconDownload", LazyIconDownload],
["IconEar", LazyIconEar],
["IconEye", LazyIconEye],
["IconEyeAndEar", LazyIconEyeAndEar],
["IconEyeAndEarFull", LazyIconEyeAndEarFull],
["IconFlag", LazyIconFlag],
["IconHeart", LazyIconHeart],
["IconLogo", LazyIconLogo],
["IconPlay", LazyIconPlay],
["IconPlus", LazyIconPlus],
["IllustrationEyesAndEars", LazyIllustrationEyesAndEars],
["IllustrationSquiggle", LazyIllustrationSquiggle],
["IllustrationSquiggleDot", LazyIllustrationSquiggleDot],
["NavigationDesktop", LazyNavigationDesktop],
["NavigationDesktopDropdown", LazyNavigationDesktopDropdown],
["NavigationFooter", LazyNavigationFooter],
["NavigationHoverUnderline", LazyNavigationHoverUnderline],
["NavigationMain", LazyNavigationMain],
["NavigationMobile", LazyNavigationMobile],
["NavigationMobilePopup", LazyNavigationMobilePopup],
["NavigationSimpleLang", LazyNavigationSimpleLang],
["NavigationTab", LazyNavigationTab],
["UtilsTime", LazyUtilsTime],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
